// network-monitor.service.ts
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NetworkMonitorService {
  private dataUsed: number = 0;

  constructor(private ngZone: NgZone) {
    this.initPerformanceMonitoring();
  }

  /**
   * Initializes PerformanceObserver to monitor resource loading.
   */
  private initPerformanceMonitoring() {
    if (window.PerformanceObserver) {
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        entries.forEach((entry) => {
          if (entry instanceof PerformanceResourceTiming) {
            this.handleResourceEntry(entry);
          }
        });
      });

      observer.observe({ type: 'resource', buffered: true });
    } else {
      console.warn('PerformanceObserver is not supported in this browser.');
    }
  }

  /**
   * Handles individual resource entries.
   * @param entry PerformanceResourceTiming entry.
   */
  private handleResourceEntry(entry: PerformanceResourceTiming) {
    const resourceSize = entry.encodedBodySize;
    const transferSize = entry.transferSize;
    const isFromCache = transferSize === 0;

    if (isFromCache) {
      // Resource served from cache
      console.debug(`Resource from cache: ${entry.name}`);
    } else {
      // Resource fetched from network
      this.dataUsed += resourceSize;
      console.debug(`Resource from network: ${entry.name}`, resourceSize);
    }

    // Log resource details
    this.ngZone.run(() => {
      console.debug(`Resource Loaded: ${entry.name}`);
      console.debug(`Size: ${resourceSize} bytes`);
      console.debug(`Load Time: ${entry.duration} ms`);
    });
  }

  /**
   * Returns the total data used in bytes.
   */
  public getDataUsed(): number {
    return this.dataUsed;
  }

  /**
   * Resets the data usage counter.
   */
  public resetDataUsed() {
    this.dataUsed = 0;
  }
}
