import set from 'lodash.set';
import get from 'lodash.get';

/**
 * Utility class providing support Object manipulation.
 *
 * @export
 * @abstract
 * @class ObjectUtil
 * @example
 * ```ts
 * import { TypeUtil } from '@proximus/bcri-ts';
 *
 * const source = {
 *      express: {
 *	        bodyLimit: '2MB',
 *			    corsWhitelist: ["http://A"],
 *			},
 *		};
 * const changes = {
 *      express: {
 *		    bodyLimit: '3MB',
 *		},
 *	};
 *  const target = ObjectUtil.deepMerge({ ...source }, changes);
 * ```
 */
export abstract class ObjectUtil {
  /* istanbul ignore next */

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * Recursive function that returns a new object without `undefined` onr `null` properties.
   *
   * + If target does already contain one property, it is overridden by target's one
   *
   * @static
   * @template T
   * @param {T} obj the target object
   * @returns {Partial<T>} the filtered object
   * @memberof ObjectUtil
   */
  static filterNullAndUndefinedProperties<T extends Record<string, any>>(
    obj: T,
  ): Partial<T> {
    return Object.keys(obj)
      .filter((k) => obj[k] != null) // Remove undef. and null.
      .reduce(
        (newObj, k) =>
          typeof obj[k] === 'object'
            ? {
                ...newObj,
                [k]: ObjectUtil.filterNullAndUndefinedProperties(obj[k]),
              } // Recurse.
            : { ...newObj, [k]: obj[k] }, // Copy value.
        {} as T,
      );
  }

  /**
   * Function that transform a dot path notation object to a nested object
   * @static
   * @param dotPathObject {"a.b.c": 'value', "a.b.d": 'value2'}
   * @returns an nested object
   * {
   *     "a": {
   *         "b": {
   *             "c": "value",
   *             "d": "value2
   *         }
   *     }
   * }
   * @memberof ObjectUtil
   */
  static dotNotationToObject(
    dotPathObject: Record<string, any>,
  ): Record<string, any> {
    const deepObject = {};
    Object.keys(dotPathObject).forEach((key) => {
      set(deepObject, key, dotPathObject[key]);
    });
    return deepObject;
  }

  static get(object: any, path: string): any {
    return get(object, path);
  }

  static isError(error: unknown): error is Error {
    return (
      (error as Error)?.name !== undefined &&
      (error as Error)?.message !== undefined
    );
  }
}
