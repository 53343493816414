import { Injectable } from '@angular/core';
import { CommandRepositoryBase } from '@freddy/common';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class CommandRepository extends CommandRepositoryBase {
  constructor(
    firestore: Firestore,
    private readonly tenantService: TenantService,
  ) {
    super(firestore);
  }

  protected getDocPath(): string {
    return `/${this.tenantService.getOrganizationPrefixPath()}games/:gameUid/commands`;
  }
}
