import { HostListener, Injectable } from '@angular/core';
import { FirebaseStorageService, LocalStorageService } from 'common';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private readonly uploadService: FirebaseStorageService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  @HostListener('window:online')
  async syncOnOnline() {
    if (navigator.onLine) {
      const keys = await this.localStorageService.keys();
      if (!keys) return;
      for (const key of keys) {
        if (key.startsWith('toUpload-')) {
          const { file, metadata } = (await this.localStorageService.get(
            key,
          )) as {
            file: File;
            metadata: Record<string, string>;
          };

          await this.uploadService.uploadFile(
            key.replace('toUpload-', ''),
            file,
            metadata,
          );
          await this.localStorageService.remove(key);
        }
      }
    }
  }
}
