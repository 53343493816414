import { Injectable } from '@angular/core';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { GameForm } from '../store/game.store';
import {
  AtLeastWithUid,
  CommonRepositoryAbstract,
  PathParams,
} from '@freddy/common';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { Game, GameStatus, Team } from '@freddy/models';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
} from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { AssetService, AssetType } from '../../../core/assets/asset.service';
import { AssetRepository } from '../../../core/assets/asset.repository';
import { RepositoryOptions } from '../../../../../../common/src/lib';

@Injectable({
  providedIn: 'root',
})
export class GameRepository extends CommonRepositoryAbstract<Game> {
  static DOC_PATH = 'games';
  static TEAMS_PATH = 'teams';
  static ANSWERS_PATH = 'answers';
  static PAGE_SIZE = 10;

  constructor(
    firestore: Firestore,
    private readonly tenantService: TenantService,
    private readonly assetService: AssetService,
    private readonly assetRepository: AssetRepository,
  ) {
    super(firestore);
  }

  async createGame(newGame: { gameForm: GameForm }) {
    const companyLogoAsset = await this.uploadCompanyLogo(newGame.gameForm);
    const game: Game = {
      ...newGame.gameForm,
      uid: GuidUtils.generateUuid(),
      code: GuidUtils.generateShortCode(),
      scenario: newGame.gameForm.scenario.item,
      status: GameStatus.PLANNED,
      companyLogo: companyLogoAsset,
      organization: this.tenantService.currentOrganizationSlug,
    };

    return firstValueFrom(this.create(game));
  }

  archiveGame(game: Game, pathParams?: PathParams): Observable<void> {
    return this.update(
      {
        uid: game.uid,
        status: GameStatus.ARCHIVED,
      },
      pathParams,
    );
  }

  async updateGame(gameToEdit: { uid: string; gameForm: GameForm }) {
    const companyLogoAsset = await this.uploadCompanyLogo(gameToEdit.gameForm);
    const game: AtLeastWithUid<Game> = {
      ...(gameToEdit.gameForm as AtLeastWithUid<any>),
      scenario: gameToEdit.gameForm.scenario.item,
      companyLogo: companyLogoAsset,
    };
    return this.update(game);
  }

  getTeamsChange(gameUid: string): Observable<Team[]> {
    return new Observable((observer) => {
      const gameDocRef = doc(this.firestore, `${this.getDocPath()}/${gameUid}`);
      const teamsCollectionRef = collection(
        gameDocRef,
        GameRepository.TEAMS_PATH,
      );

      const unsubscribe = onSnapshot(
        teamsCollectionRef,
        (snapshot) => {
          const teams: Team[] = snapshot.docs.map(
            (doc) => ({ uid: doc.id, ...doc.data() }) as Team,
          );
          observer.next(teams);
        },
        observer.error,
      );

      return { unsubscribe };
    });
  }

  excludeTeam(game: Game, team: Team): Promise<void> {
    const teamDocRef = doc(
      this.firestore,
      `${this.getDocPath()}/${game.uid}/${GameRepository.TEAMS_PATH}/${team.uid}`,
    );
    return deleteDoc(teamDocRef);
  }

  getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() + GameRepository.DOC_PATH
    );
  }

  async cloneGame(game: Game): Promise<Game> {
    const clonedGame: Game = {
      ...game,
      uid: GuidUtils.generateUuid(),
      code: GuidUtils.generateShortCode(),
      name: `${game.name} - Cloned`,
      status: GameStatus.PLANNED,
      pausedAt: null, // Reset paused date
      plannedEndDate: undefined, // Reset planned end date
      createdAt: new Date(),
      scheduledGameDate: null, // Reset scheduled date
    };

    return firstValueFrom(this.create(clonedGame));
  }

  private async uploadCompanyLogo(formData: GameForm) {
    if (formData.uid) {
      const previousGame = await firstValueFrom(this.get(formData.uid));
      if (previousGame.companyLogo) {
        this.assetRepository.delete({
          uid: previousGame.companyLogo,
        });
      }
    }
    if (formData.companyLogo && formData.companyLogo[0]) {
      return (
        await this.assetService.uploadAndCreateAsset(formData.companyLogo[0], {
          type: AssetType.COMPANY_LOGO,
        })
      ).uid;
    }
    return null;
  }

  override getRepositoryOptions(): RepositoryOptions {
    return {
      pageSize: GameRepository.PAGE_SIZE,
    };
  }
}
