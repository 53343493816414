import * as SentryAngular from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';
import { environment } from './environments/environment';
import {
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import {
  createTracingProviders,
  NgxsSentryBreadcrumbsService,
} from '@freddy/common';
import { SOUND_TOKEN } from './app/core/services/sound.service';
import { THEME_TOKEN } from './app/core/services/theme.service';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { CoreModule } from './app/core/core.module';
import { AppRoutingModule } from './app/app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app/app.component';
import themesJson from './assets/theme/theme.config.json';
import soundsJson from './assets/theme/tmu/sounds/tmu.sounds.json';
import packageJson from '../../../package.json';
import { provideStore } from '@ngxs/store';
import { ConnectState } from './app/core/stores/connect.store';
import { InGameState } from './app/core/stores/in-game.store';
import { ChallengeState } from './app/core/stores/challenge.store';
import { MissionState } from './app/core/stores/mission.store';
import { ChatState } from './app/chat/store/chat.store';
import { withNgxsFormPlugin } from '@ngxs/form-plugin';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin';
import { GameState } from '../../freddy-admin/src/app/features/game/store/game.store';

const appVersion = packageJson.version;

// @ts-ignore
window['FIREBASE_APPCHECK_DEBUG_TOKEN'] = false;
// Call the element loader after the platform has been bootstrapped

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    SentryAngular.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    SentryAngular.replayCanvasIntegration(),
  ],
  release: environment.version,
  sendClientReports: environment.sentry.sendClientReports,
  environment: environment.name,
  // Performance Monitoring
  tracesSampleRate: environment.sentry.tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const bootstrap = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      {
        provide: NgxsSentryBreadcrumbsService,
        useClass: NgxsSentryBreadcrumbsService,
      },
      provideAppInitializer(() => {
        inject(NgxsSentryBreadcrumbsService);
      }),
      provideStore(
        [
          ConnectState,
          InGameState,
          ChallengeState,
          MissionState,
          ChatState,
          GameState,
          MissionState,
        ],
        {
          developmentMode: environment.production,
        },
        withNgxsFormPlugin(),
        withNgxsReduxDevtoolsPlugin({
          name: 'Freddy',
          disabled: environment.production,
        }),
        withNgxsLoggerPlugin({
          filter: (action, state) =>
            !action.constructor.type?.includes('@@') &&
            !action?.constructor.type?.includes('[Forms]'),
        }),
      ),
      importProvidersFrom(
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000',
        }),
      ),
      ...createTracingProviders(environment, appVersion),
      {
        provide: SOUND_TOKEN,
        useValue: soundsJson,
      },
      {
        provide: THEME_TOKEN,
        useValue: themesJson,
      },
      provideIonicAngular({
        mode: 'ios',
        swipeBackEnabled: false,
        hardwareBackButton: false,
      }),
    ],
  });

bootstrap().catch((err) => console.error(err));
