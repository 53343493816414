import { NgModule } from '@angular/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FirebaseModule } from './firebase/firebase.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  IModuleTranslationOptions,
  ModuleTranslateLoader,
} from '@larscom/ngx-translate-module-loader';
import { TimerService, UpdateService } from 'common';
import { SyncService } from './services/sync.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function moduleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = './assets/i18n';

  const options: IModuleTranslationOptions = {
    lowercaseNamespace: true,
    modules: [
      // final url: ./assets/i18n/chat/en.json
      { baseTranslateUrl, moduleName: 'chat' },
      { baseTranslateUrl, moduleName: 'connect' },
      { baseTranslateUrl, moduleName: 'game' },
      { baseTranslateUrl, moduleName: 'common' },
    ],
  };

  return new ModuleTranslateLoader(http, options);
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: moduleHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FirebaseModule,
    IonicStorageModule.forRoot({
      name: '__freddy',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
  ],
  providers: [
    TimerService,
    SyncService,
    UpdateService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
