export class CreateChatAction {
  static readonly type = '[Chat] Create chat';
  constructor(public readonly teamUid: string) {}
}

export class SaveMessageAction {
  static readonly type = '[Chat] Save message';
  constructor(
    public readonly message: string,
    public readonly teamUid?: string,
  ) {}
}

export class SendMessageToUserAction {
  static readonly type = '[Chat] Send message to team';
  constructor(
    public readonly message: string,
    public readonly teamUid: string,
    public readonly metadata?: Record<string, string>,
  ) {}
}

export class SelectChatAction {
  static readonly type = '[Chat] Select chat';
  constructor(public readonly teamUid: string) {}
}

export class SelectChannelAction {
  static readonly type = '[Chat] Select channel';
  constructor(public readonly chatId: string) {}
}

export class ListenMessagesAction {
  static readonly type = '[Chat] Listen messages';
  constructor(public readonly chatUid: string) {}
}

export class AddPointsAction {
  static readonly type = '[Chat] Add points';
  constructor() {}
}

export class RemovePointsAction {
  static readonly type = '[Chat] Remove points';
  constructor() {}
}

export class RestartApplicationAction {
  static readonly type = '[Chat] Restart application';
  constructor() {}
}

export class DisconnectPlayerAction {
  static readonly type = '[Chat] Disconnect player';
  constructor() {}
}
