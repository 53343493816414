<!-- src/app/privacy-policy/privacy-policy.component.html -->
<h1>Privacy Policy</h1>
<p>Last updated: [Date]</p>

<h2>Introduction</h2>
<p>
  [Your Company Name] ("we", "our", or "us") is committed to protecting your
  privacy. This Privacy Policy explains how we collect, use, and disclose your
  information when you use our [name of your application] (the "Service").
</p>

<h2>Information We Collect</h2>
<h3>1. Personal Information</h3>
<p>
  <strong>Registration Information:</strong>
  When you register for an account, we may collect personal information such as
  your name, email address, phone number, and password.
</p>
<p>
  <strong>Profile Information:</strong>
  You may provide additional information as part of your profile, including a
  profile picture, address, and other details.
</p>
<p>
  <strong>Communication Information:</strong>
  We may collect information when you contact us for support or other inquiries.
</p>

<h3>2. Usage Information</h3>
<p>
  <strong>Log Data:</strong>
  We automatically collect information about your interactions with the Service,
  such as IP address, browser type, referring/exit pages, and timestamps.
</p>
<p>
  <strong>Cookies and Similar Technologies:</strong>
  We use cookies to track your activity on our Service and hold certain
  information. You can instruct your browser to refuse all cookies or to
  indicate when a cookie is being sent.
</p>

<h3>3. Location Information</h3>
<p>
  <strong>Geolocation Data:</strong>
  With your consent, we may collect and process information about your actual
  location. We use various technologies to determine location, including IP
  address and GPS.
</p>

<h2>How We Use Your Information</h2>
<p>We use the information we collect for the following purposes:</p>
<ul>
  <li>
    <strong>To Provide and Maintain Our Service:</strong>
    We use your information to provide, operate, and maintain our Service.
  </li>
  <li>
    <strong>To Improve Our Service:</strong>
    We analyze usage information to understand and improve our Service.
  </li>
  <li>
    <strong>To Communicate with You:</strong>
    We use your information to send you updates, technical notices, security
    alerts, and support messages.
  </li>
  <li>
    <strong>For Marketing and Advertising:</strong>
    With your consent, we may use your information to send promotional messages
    and advertisements.
  </li>
  <li>
    <strong>To Ensure Security:</strong>
    We use your information to detect, prevent, and address fraud and other
    illegal activities.
  </li>
</ul>

<h2>How We Share Your Information</h2>
<p>
  We do not share your personal information with third parties except as
  described in this Privacy Policy:
</p>
<p>
  <strong>With Your Consent:</strong>
  We may share your information with third parties when we have your consent to
  do so.
</p>
<p>
  <strong>Service Providers:</strong>
  We may share your information with third-party service providers who perform
  services on our behalf, such as payment processing, data analysis, and email
  delivery.
</p>
<p>
  <strong>Legal Requirements:</strong>
  We may disclose your information if required to do so by law or in response to
  valid requests by public authorities.
</p>

<h2>Security of Your Information</h2>
<p>
  We take reasonable measures to protect your information from unauthorized
  access, use, or disclosure. However, no method of transmission over the
  Internet or electronic storage is completely secure.
</p>

<h2>Your Rights</h2>
<p>
  Depending on your location, you may have certain rights regarding your
  personal information, including the right to access, correct, or delete your
  personal information. To exercise these rights, please contact us at [contact
  email].
</p>

<h2>Children's Privacy</h2>
<p>
  Our Service does not address anyone under the age of 13. We do not knowingly
  collect personally identifiable information from children under 13. If you are
  a parent or guardian and you are aware that your child has provided us with
  personal information, please contact us.
</p>

<h2>Changes to This Privacy Policy</h2>
<p>
  We may update our Privacy Policy from time to time. We will notify you of any
  changes by posting the new Privacy Policy on this page. You are advised to
  review this Privacy Policy periodically for any changes.
</p>

<h2>Contact Us</h2>
<p>
  If you have any questions about this Privacy Policy, please contact us at:
</p>
<p>
  BCDI SNC
  <br />
  privacy&#64;gamemaster.be
  <br />
</p>
