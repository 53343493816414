import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { Observable, Subscription, Subject, firstValueFrom } from 'rxjs';
import { Command, Team, TeamState } from '@freddy/models';
import { tap, takeUntil, filter } from 'rxjs/operators';
import { CommandRepository } from '../repository/command.repository';
import { PathParams } from '../../../../../common/src/lib';

@Injectable({
  providedIn: 'root',
})
export class CommandService implements OnDestroy {
  @Select(InGameState.myTeam)
  private myTeam$!: Observable<Team>;

  private commandQueue: Command[] = [];
  private isProcessingCommand = false;
  private teamSubscription: Subscription | undefined;
  private destroy$ = new Subject<void>();
  private playerStatus?: TeamState;

  constructor(
    private readonly store: Store,
    private readonly commandRepository: CommandRepository,
  ) {
    this.subscribeToTeamChanges();
  }

  private subscribeToTeamChanges() {
    this.teamSubscription = this.myTeam$
      .pipe(
        tap((team) => {
          if (team) {
            this.playerStatus = team.currentState;
            this.tryProcessingNextCommand();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  sendCommand(command: Command, pathParams?: PathParams): Observable<void> {
    console.info(`Adding command to queue: ${command.action}`);
    return this.commandRepository.sendCommand(command, pathParams);
  }

  private tryProcessingNextCommand() {
    if (
      this.playerStatus === 'AVAILABLE' &&
      !this.isProcessingCommand &&
      this.commandQueue.length > 0
    ) {
      this.isProcessingCommand = true;
      const command = this.commandQueue.shift();
      if (command) {
        this.executeCommand(command).then(
          () => {
            this.isProcessingCommand = false;
            this.tryProcessingNextCommand();
          },
          (error) => {
            console.error(`Failed to execute command: ${error}`);
            this.isProcessingCommand = false;
            // Optionally, handle the error (e.g., retry logic)
            this.tryProcessingNextCommand();
          },
        );
      }
    }
  }

  private async executeCommand(command: Command): Promise<void> {
    console.log(`Executing command: ${command.action}`);
    try {
      await firstValueFrom(this.store.dispatch(command.action));
      await firstValueFrom(
        this.commandRepository.markCommandAsExecuted(command.uid),
      );
      console.log(`Command executed: ${command.action}`);
    } catch (error) {
      console.error(`Error executing command: ${error}`);
      throw error;
    }
  }

  listenForCommands(teamUid: string): void {
    if (teamUid) {
      this.commandRepository
        .listenForCommands(teamUid)
        .pipe(
          filter((commands) => commands && commands.length > 0),
          tap((commands) => {
            this.commandQueue.push(...commands);
            this.tryProcessingNextCommand();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.teamSubscription) {
      this.teamSubscription.unsubscribe();
    }
  }
}
