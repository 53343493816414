import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { createTracingProviders } from '@freddy/common';
import { SOUND_TOKEN } from './core/services/sound.service';
import { THEME_TOKEN } from './core/services/theme.service';
import themesJson from '../assets/theme/theme.config.json';

import soundsJson from '../assets/theme/tmu/sounds/tmu.sounds.json';

import packageJson from '../../../../package.json';
const appVersion = packageJson.version;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false,
      hardwareBackButton: false,
    }),
    CoreModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ...createTracingProviders(environment, appVersion),
    {
      provide: SOUND_TOKEN,
      useValue: soundsJson,
    },
    {
      provide: THEME_TOKEN,
      useValue: themesJson,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
