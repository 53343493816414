import { QueryParam } from '@freddy/common';
import { Answer, Game, Mission, Team } from '@freddy/models';

export class SaveGameAction {
  static readonly type = '[Game] Save the game';
  constructor() {}
}

export class GameCreatedAction {
  static readonly type = '[Game] The game has been created';
  constructor(public readonly game: Game) {}
}

export class SearchGamesAction {
  static readonly type = '[Game] Search Games';
  constructor(
    public readonly searchTerm: string,
    public readonly page: number = 1,
    public readonly pageSize: number = 10,
  ) {}
}

export class SearchGamesSuccessAction {
  static readonly type = '[Game] Search Games Success';
  constructor(
    public readonly games: Game[],
    public readonly totalResults: number,
  ) {}
}

export class SearchGamesFailureAction {
  static readonly type = '[Game] Search Games Failure';
  constructor(public readonly error: any) {}
}

export class NextGamePageAction {
  static readonly type = '[Game] Next Game Page';
}

export class PrevGamePageAction {
  static readonly type = '[Game] Previous Game Page';
}

export class ListenToTeamPositionsAction {
  static readonly type = '[Game] Listen to team positions';
  constructor(public readonly gameUid: string) {}
}

export class ListGamesAction {
  static readonly type = '[Game] List games';

  constructor(public readonly query: QueryParam) {}
}

export class EditGameAction {
  static readonly type = '[Game] Edit game';
  constructor(public readonly game: Game) {}
}

export class ArchiveGameAction {
  static readonly type = '[Game] Archive Game';
  constructor(public readonly game: Game) {}
}

export class CloneGameAction {
  static readonly type = '[Game] Clone game';
  constructor(public readonly game: Game) {}
}

export class ControlGameAction {
  static readonly type = '[Game] Control the current game';
  constructor(public readonly game: Game) {}
}

export class DownloadGameAction {
  static readonly type = '[Game] Download the current game';
  constructor(public readonly game: Game) {}
}

export class ResetGameFormAction {
  static readonly type = '[Game] Reset game form';
  constructor() {}
}

export class ListenToGameChangesAction {
  static readonly type =
    '[Game] It will listen to a game changes to update the store';
  constructor(public readonly gameUid: string) {}
}

export class PatchTeamOrGameAction {
  static readonly type = '[Game] Patch team or game';
  constructor(
    public readonly game?: Game,
    public readonly teams?: Team[],
  ) {}
}

export class ClearListenToGameChangesAction {
  static readonly type = '[Game] Clear the game changes subscription';
  constructor() {}
}

export class ExcludeTeamAction {
  static readonly type = '[Game] Exclude team';
  constructor(public readonly team: Team) {}
}

export class StartMissionsForAllTeamsActions {
  static readonly type = '[Game] Start the mission for every players';
  constructor(public readonly missionUid: string) {}
}

export class CancelMissionAction {
  static readonly type = '[Game] Cancel mission for every players';
  constructor(public readonly missionUid: string) {}
}

export class StartGameAction {
  static readonly type = '[Game] Start the current game';
  constructor() {}
}

export class StopGameAction {
  static readonly type = '[Game] Stop the current game';
  constructor() {}
}

export class PauseGameAction {
  static readonly type = '[Game] Pause the game';
  constructor() {}
}

export class AddTimeAction {
  static readonly type = '[Game] Add time to the game';
  constructor() {}
}

export class RemoveTimeAction {
  static readonly type = '[Game] Remove time to the game';
  constructor() {}
}

export class EvaluateAnswerAction {
  static readonly type = '[Game] Answer evaluated';
  constructor(
    public readonly answer: Answer,
    public readonly points: number,
    public readonly team: Team,
    public readonly game: Game,
    public readonly mission: Mission,
  ) {}
}
