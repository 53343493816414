{
  "soundTypes": [
    {
      "type": "click-button",
      "sounds": [
        {
          "id": "click",
          "filePath": "assets/theme/tmu/sounds/click-button.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "click-button-back",
      "sounds": [
        {
          "id": "click",
          "filePath": "assets/theme/tmu/sounds/click-button-back.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "warning-click",
      "sounds": [
        {
          "id": "warning-click",
          "filePath": "assets/theme/tmu/sounds/warning-click.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "emoticon-reaction",
      "sounds": [
        {
          "id": "click",
          "filePath": "assets/theme/tmu/sounds/emoticon-reaction.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "checking-device",
      "sounds": [
        {
          "id": "checking-device",
          "filePath": "assets/theme/tmu/sounds/checking-device.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "minor-error",
      "sounds": [
        {
          "id": "minor-error",
          "filePath": "assets/theme/tmu/sounds/minor-error.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "message-sent",
      "sounds": [
        {
          "id": "click",
          "filePath": "assets/theme/tmu/sounds/message-sent.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "photo-taken",
      "sounds": [
        {
          "id": "photo-taken",
          "filePath": "assets/theme/tmu/sounds/photo-taken.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "start-duel",
      "sounds": [
        {
          "id": "start-duel",
          "filePath": "assets/theme/tmu/sounds/start-duel.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "end-game",
      "sounds": [
        {
          "id": "end-game",
          "filePath": "assets/theme/tmu/sounds/end-game.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "new-message-received",
      "sounds": [
        {
          "id": "new-message-received",
          "filePath": "assets/theme/tmu/sounds/new-message-received.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "new-challenge-possible",
      "sounds": [
        {
          "id": "new-challenge-possible",
          "filePath": "assets/theme/tmu/sounds/new-challenge-possible.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "challenge-won",
      "sounds": [
        {
          "id": "challenge-won",
          "filePath": "assets/theme/tmu/sounds/challenge-won.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "challenge-lost",
      "sounds": [
        {
          "id": "challenge-lost",
          "filePath": "assets/theme/tmu/sounds/challenge-lost.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "timer-start",
      "sounds": [
        {
          "id": "timer-start",
          "filePath": "assets/theme/tmu/sounds/timer-start.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "mic-started",
      "sounds": [
        {
          "id": "mic-started",
          "filePath": "assets/theme/tmu/sounds/mic-started.mp3",
          "loop": false
        }
      ]
    },
    {
      "type": "arrived-at-destination",
      "sounds": [
        {
          "id": "arrived-at-destination",
          "filePath": "assets/theme/tmu/sounds/arrived-at-destination-1.mp3",
          "loop": false
        },
        {
          "id": "arrived-at-destination",
          "filePath": "assets/theme/tmu/sounds/arrived-at-destination-2.mp3",
          "loop": false
        },
        {
          "id": "arrived-at-destination",
          "filePath": "assets/theme/tmu/sounds/arrived-at-destination-3.mp3",
          "loop": false
        },
        {
          "id": "arrived-at-destination",
          "filePath": "assets/theme/tmu/sounds/arrived-at-destination-4.mp3",
          "loop": false
        }
      ]
    }
  ]
}
