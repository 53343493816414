import { createAnimation } from '@ionic/angular';
import {
  query,
  animate,
  group,
  style,
  transition,
  trigger,
  AnimationMetadata,
} from '@angular/animations';

interface AnimationConfig {
  duration: number;
  performanceMode?: 'low' | 'medium' | 'high';
  debug?: boolean;
}

class AnimationManager {
  private static readonly DEFAULT_CONFIG: AnimationConfig = {
    duration: 300,
    performanceMode: 'high',
  };

  private static readonly DEBUG_DURATION = 10000;

  private static readonly EASING = {
    enter: 'cubic-bezier(0.3, 0, 0.66, 1)',
    leave: 'cubic-bezier(0.34, 0.01, 0.67, 1)',
    smooth: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  };

  private static getConfig(
    customConfig?: Partial<AnimationConfig>,
  ): AnimationConfig {
    const config = { ...this.DEFAULT_CONFIG, ...customConfig };
    return config.debug ? { ...config, duration: this.DEBUG_DURATION } : config;
  }

  private static shouldReduceMotion(): boolean {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }

  private static getDuration(config: AnimationConfig): number {
    if (this.shouldReduceMotion()) {
      return Math.min(config.duration, 200); // Cap at 200ms for reduced motion
    }

    switch (config.performanceMode) {
      case 'low':
        return Math.floor(config.duration * 0.7);
      case 'medium':
        return config.duration;
      case 'high':
        return config.duration;
      default:
        return config.duration;
    }
  }

  static createTopToBottom(
    baseEl: HTMLElement,
    opts?: any,
    config?: Partial<AnimationConfig>,
  ) {
    const finalConfig = this.getConfig(config);
    const duration = this.getDuration(finalConfig);

    try {
      if (opts.direction === 'forward') {
        return createAnimation()
          .addElement(opts.enteringEl)
          .duration(duration)
          .iterations(1)
          .easing(this.EASING.enter)
          .beforeStyles({
            'transform-origin': 'top',
            'will-change': 'transform, opacity',
            'z-index': '1',
          })
          .fromTo('transform', 'translateY(-100%)', 'translateY(0)')
          .fromTo('opacity', '0.5', '1')
          .afterStyles({
            'will-change': 'auto',
          });
      } else {
        return createAnimation()
          .addElement(opts.leavingEl)
          .duration(duration)
          .iterations(1)
          .easing(this.EASING.leave)
          .beforeStyles({
            'transform-origin': 'bottom',
            'will-change': 'transform, opacity',
            'z-index': '0',
          })
          .fromTo('transform', 'translateY(0)', 'translateY(100%)')
          .fromTo('opacity', '1', '0.5')
          .afterStyles({
            'will-change': 'auto',
          });
      }
    } catch (error) {
      console.warn('Animation error:', error);
      return createAnimation();
    }
  }

  static createPageTransition(
    baseEl: HTMLElement,
    opts?: any,
    config?: Partial<AnimationConfig>,
  ) {
    const finalConfig = this.getConfig(config);
    const duration = this.getDuration(finalConfig);

    try {
      if (opts.direction === 'forward') {
        return createAnimation()
          .addElement(opts.enteringEl)
          .duration(duration)
          .easing(this.EASING.smooth)
          .beforeStyles({
            'will-change': 'transform, opacity',
            'transform-origin': 'right',
            'backface-visibility': 'hidden',
          })
          .fromTo('transform', 'translateX(100%)', 'translateX(0)')
          .fromTo('opacity', 0.8, 1)
          .afterStyles({
            'will-change': 'auto',
            'backface-visibility': 'visible',
          });
      } else {
        return createAnimation()
          .addElement(opts.leavingEl)
          .duration(duration)
          .iterations(1)
          .easing(this.EASING.smooth)
          .beforeStyles({
            'will-change': 'transform, opacity',
            'transform-origin': 'left',
            'backface-visibility': 'hidden',
          })
          .fromTo('transform', 'translateX(0)', 'translateX(-100%)')
          .fromTo('opacity', '1', '0.8')
          .afterStyles({
            'will-change': 'auto',
            'backface-visibility': 'visible',
          });
      }
    } catch (error) {
      console.warn('Animation error:', error);
      return createAnimation();
    }
  }

  static createSlideAnimation(
    config?: Partial<AnimationConfig>,
  ): AnimationMetadata[] {
    const finalConfig = this.getConfig(config);
    const duration = this.getDuration(finalConfig);

    return [
      style({ position: 'relative', contain: 'layout style paint' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            contain: 'layout style paint',
            'will-change': 'transform, opacity',
            'backface-visibility': 'hidden',
          }),
        ],
        { optional: true },
      ),
      query(
        ':enter',
        [style({ transform: 'translateX(100%)', opacity: 0.8 })],
        {
          optional: true,
        },
      ),
      query(':leave', [style({ transform: 'translateX(0)', opacity: 1 })], {
        optional: true,
      }),
      group([
        query(
          ':leave',
          [
            animate(
              `${duration}ms 50ms ${this.EASING.smooth}`,
              style({ transform: 'translateX(-100%)', opacity: 0.8 }),
            ),
          ],
          { optional: true },
        ),
        query(
          ':enter',
          [
            animate(
              `${duration}ms ${this.EASING.smooth}`,
              style({ transform: 'translateX(0)', opacity: 1 }),
            ),
          ],
          { optional: true },
        ),
      ]),
    ];
  }

  static createFadeAnimation(
    config?: Partial<AnimationConfig>,
  ): AnimationMetadata[] {
    const finalConfig = this.getConfig(config);
    const duration = this.getDuration(finalConfig);

    return [
      style({ position: 'relative', contain: 'layout style paint' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            'will-change': 'opacity',
            contain: 'layout style paint',
          }),
        ],
        { optional: true },
      ),
      query(':enter', [style({ opacity: 0 })], { optional: true }),
      group([
        query(
          ':leave',
          [
            animate(
              `${duration}ms ${this.EASING.smooth}`,
              style({ opacity: 0 }),
            ),
          ],
          { optional: true },
        ),
        query(
          ':enter',
          [
            animate(
              `${duration}ms ${this.EASING.smooth}`,
              style({ opacity: 1 }),
            ),
          ],
          { optional: true },
        ),
      ]),
    ];
  }
}

// Export the animations with performance modes
export const topToBottomTransition = (baseEl: HTMLElement, opts?: any) =>
  AnimationManager.createTopToBottom(baseEl, opts, {
    performanceMode: navigator.hardwareConcurrency <= 4 ? 'low' : 'high',
  });

export const pageTransitionAnimations = (baseEl: HTMLElement, opts?: any) =>
  AnimationManager.createPageTransition(baseEl, opts, {
    performanceMode: navigator.hardwareConcurrency <= 4 ? 'low' : 'high',
  });

// Angular animations
export const slideAnimation = trigger('slideAnimation', [
  transition(
    '* <=> *',
    AnimationManager.createSlideAnimation({
      performanceMode: navigator.hardwareConcurrency <= 4 ? 'low' : 'high',
    }),
  ),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(
    '* <=> *',
    AnimationManager.createFadeAnimation({
      performanceMode: navigator.hardwareConcurrency <= 4 ? 'low' : 'high',
    }),
  ),
]);
