{
  "defaultThemeId": "freddy",
  "themes": [
    {
      "id": "freddy",
      "stylesheet": "freddy",
      "sounds": {
        "en": "assets/theme/freddy/sounds/en/freddy.sounds.json",
        "fr": "assets/theme/freddy/sounds/fr/freddy.sounds.json",
        "nl": "assets/theme/freddy/sounds/nl/freddy.sounds.json",
        "nl-BE": "assets/theme/freddy/sounds/nl-BE/freddy.sounds.json"
      }
    },
    {
      "id": "tmu",
      "stylesheet": "tmu",
      "sounds": {
        "default": "assets/theme/tmu/sounds/tmu.sounds.json"
      }
    }
  ]
}
