import { CAP_GLOBAL_OBJ } from '../utils/webViewUrl';
import { utf8ToBytes } from '../vendor';
export const useEncodePolyfill = () => {
  if (!CAP_GLOBAL_OBJ.__SENTRY__) {
    CAP_GLOBAL_OBJ.__SENTRY__ = {};
  }
  CAP_GLOBAL_OBJ.__SENTRY__.encodePolyfill = encodePolyfill;
};
export const encodePolyfill = text => {
  const bytes = new Uint8Array(utf8ToBytes(text));
  return bytes;
};
