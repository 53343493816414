import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as SentryAngular from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';
// @ts-ignore
window['FIREBASE_APPCHECK_DEBUG_TOKEN'] = false;
// Call the element loader after the platform has been bootstrapped
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    SentryAngular.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    SentryAngular.replayCanvasIntegration(),
  ],
  release: environment.version,
  sendClientReports: environment.sentry.sendClientReports,
  environment: environment.name,
  // Performance Monitoring
  tracesSampleRate: environment.sentry.tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
