import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { environment } from '../environments/environment';
import { pageTransitionAnimations } from './core/utils/animation.utils';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService, LocalStorageService, UpdateService } from 'common';
import { Locale } from 'locale-enum';
import { ThemeService } from './core/services/theme.service';
import { ActivatedRoute } from '@angular/router';

import packageJson from '../../../../package.json';
const appVersion = packageJson.version;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    const splash = document.querySelector('.splash-screen');
    setTimeout(() => {
      splash?.classList.add('hide-splash');
      if (this.activatedRoute.snapshot.queryParams['code']) {
        this.localStorageService.set(
          'gameCode',
          this.activatedRoute.snapshot.queryParams['code'],
        );
      }
    }, 2000);
  }

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private themeService: ThemeService,
    private readonly updateService: UpdateService,
    private localStorageService: LocalStorageService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.initializeTranslation();
  }

  title = 'freddy';
  commit = environment.version;
  tag = appVersion;

  private initializeTranslation(): void {
    // Subscribe to the list of available languages
    this.languageService
      .getAvailableLanguages()
      .subscribe((availableLanguages) => {
        const browserLang = environment.production ? navigator.language : 'en';

        // Check if the browser language is available
        let supportedLang = availableLanguages.find((lang) =>
          browserLang.startsWith(lang),
        );

        // If the browser language is not supported, use English as default
        supportedLang = supportedLang ? supportedLang : Locale.en;

        // Set the default language and the current language
        this.translate.setDefaultLang(Locale.en); // English as a fallback
        this.translate.use(supportedLang);
      });
  }

  protected readonly pageTransitionAnimations = pageTransitionAnimations;
}
