<ng-container *ngIf="leaderboard$ | async as leaderboard">
  <div class="background">
    <div class="container">
      <div class="leaders">
        <h2>Freddy</h2>
        <ul>
          <li
            *ngFor="let team of getTopThree(leaderboard.teams); let i = index"
          >
            <div class="lead-cats" *ngIf="team; else emptyPodium">
              <img
                class="lead-cats__photo"
                [ngSrc]="team.photoUrl"
                [width]="100"
                [height]="100"
                [alt]="team.name"
                [class.active]="team.name === newLeader"
              />
              <div class="podium" [class]="'pod-' + (i + 1)">
                <div
                  class="ranking-lead"
                  [style.backgroundColor]="getCatRankColorPodium(i)"
                >
                  {{ catRank[i].r }}
                </div>
                <h4>{{ team.name }}</h4>
                <p>{{ team.points }} points</p>
              </div>
            </div>
            <ng-template #emptyPodium>
              <div class="lead-cats">
                <div class="podium" [class]="'pod-' + (i + 1)">
                  <div
                    class="ranking-lead"
                    [style.backgroundColor]="getCatRankColorPodium(i)"
                  >
                    {{ catRank[i].r }}
                  </div>
                  <h4></h4>
                  <p>0 points</p>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="board">
        <ul>
          <li
            *ngFor="let team of leaderboard.teams; let i = index"
            class="cat-item"
          >
            <div class="cat-item__photo">
              <div
                class="ranking"
                [style.backgroundColor]="getCatRankColor(i + 1)"
              >
                {{ i + 1 }}
              </div>
              <img
                [width]="50"
                [height]="50"
                [ngSrc]="team.photoUrl"
                [alt]="team.name"
              />
            </div>
            <div class="cat-item__info">
              <h4>{{ team.name }}</h4>
            </div>
            <div class="cat-item__points">
              <p>{{ team.points }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
